import { IconButton, TableCell, TableRow } from "@mui/material";
import { capitalCase, snakeCase } from "change-case";
import Iconify from "src/components/Iconify";
import Ternary from "src/components/ternary";
import { formatAmount } from "src/pages/user/incomeReport/components/row";
import { checkIsWhite } from "../../financial/ewallet/components/dataTable";
import { Currency } from "src/components/with-prefix";

export const filterType = (type) => {
  switch (snakeCase(type)) {
    case "binary_bonus":
      return "Binary Bonus";
    case "daily_bonus":
      return "Daily Profit";
    case "ambassidor_direct_bonus":
      return "Direct Bonus";
    case "ambassidor_indirect_bonus":
      return "Indirect Bonus";

    default:
      return type;
  }
};
const DataList = ({ income, rowNumber }) => {
  const { id, row } = income;

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row">
        {rowNumber}
      </TableCell>
      <TableCell>{income?.user?.username}</TableCell>
      <TableCell>{capitalCase(filterType(income?.payment_type))}</TableCell>
      <TableCell>
        {/* <Ternary
          when={income.type === "debit"}
          then={
            <span style={{ color: "red" }}>
              -{formatAmount(income.total_amount)}
            </span>
          }
          otherwise={
            <>
              <span style={{ color: "#00c59e" }}>
                +{formatAmount(income.total_amount)}{" "}
              </span>
            </>
          }
        /> */}
        <Ternary
          when={snakeCase(income.payment_type) === "fund_transfer"}
          then={<span><Currency>{(income.payable_amount)}</Currency></span>}
          otherwise={
            <Ternary
              when={income.type === "debit"}
              then={
                <>
                  <Ternary
                    when={checkIsWhite(snakeCase(income.payment_type))}
                    then={
                      <span style={{ color: "white" }}>
                       <Currency>{(income.payable_amount)}</Currency>
                      </span>
                    }
                    otherwise={
                      <span style={{ color: "red" }}>
                        -<Currency>{(income.payable_amount)}</Currency>
                      </span>
                    }
                  />
                </>
              }
              otherwise={
                <>
                  <Ternary
                    when={checkIsWhite(snakeCase(income.payment_type))}
                    then={
                      <span style={{ color: "white" }}>
                        <Currency>{(income.payable_amount)}</Currency>
                      </span>
                    }
                    otherwise={
                      <span style={{ color: "#00c59e" }}>
                        +<Currency>{(income.payable_amount)}</Currency>{" "}
                      </span>
                    }
                  />
                </>
              }
            />
          }
        />
      </TableCell>
      <TableCell>
        {new Date(income?.created_at).toLocaleDateString("en-GB")}
      </TableCell>
    </TableRow>
  );
};

export default DataList;
