import { lazy } from "react";
import { Navigate } from "react-router";
import Ewallet from "src/pages/user/financial/ewallet";
import FundTransfer from "src/pages/user/financial/fundTransfer";
import Loadable from "../Loadable";
const DepositWallet = Loadable(
  lazy(() => import("src/pages/user/financial/deposit"))
);
const StakingWallet = Loadable(
  lazy(() => import("src/pages/user/financial/staking"))
);
const RequestPayout = Loadable(
  lazy(() => import("src/pages/user/financial/requestPayout/index"))
);

const financial = {
  path: "financial",
  children: [
    { index: true, element: <Navigate to="e-wallet" /> },
    { path: "e-wallet", element: <Ewallet /> },
    { path: "deposit-wallet", element: <DepositWallet /> },
    { path: "staking-wallet", element: <StakingWallet /> },
    { path: "funds-transfer", element: <FundTransfer /> },
    { path: "payout", element: <RequestPayout /> },
  ],
};

export default financial;
