// utils/axios.js
import axios from "axios";
import { CURRENCY, HOST_API } from "../config";

// Create an Axios instance with the base URL from your config
const axiosInstance = axios.create({
  baseURL: HOST_API, // e.g., "http://127.0.0.1:8000/"
  // Remove default 'Content-Type' to allow Axios to set it per request
  // Especially important if you're sending FormData or JSON
});

// Request interceptor to attach the JWT token to every request if it exists
axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("accessToken");
    const currency = localStorage.getItem("currency");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["front-end-path"] = window.location.pathname;
    }
    if (currency) {
      config.headers.Currency = currency;
    } else {
      config.headers.Currency = CURRENCY;
    }
    return config;
  },
  function (error) {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 Unauthorized errors globally
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code within the range of 2xx will trigger this function
    return response;
  },
  function (error) {
    // Any status codes outside the range of 2xx will trigger this function
    if (error.response && error.response.status === 401) {
      // Remove authentication-related items from localStorage
      localStorage.removeItem("isAdmin");
      localStorage.removeItem("isSubAdmin");
      localStorage.removeItem("isImpersonate");
      localStorage.removeItem("accessToken"); // Ensure token is removed

      // Optionally, you can also clear other relevant data here

      // Redirect to the login page
      window.location.href = "/auth/login";
    }

    // Optionally handle other status codes (e.g., 403, 500) here

    // Return the error to be handled by the calling function
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
