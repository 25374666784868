import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Card, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { useGetCurrencySymbol } from "src/components/with-prefix";
import useErrors from "src/hooks/useErrors";
import useLocales from "src/hooks/useLocales";
import fetchUser from "src/utils/fetchUser";
import { number, object } from "yup";

const genSchema = (limit) => {
  const schema = object().shape({
    amount: number()
      .typeError("Amount is required")
      .min(1, "The amount should be at least 1")
      .max(
        limit,
        limit <= 0
          ? "You don't have enough balance"
          : `The amount should be less than ${limit}`
      )
      .required("Amount is required"),
  });

  return schema;
};

const PayNow = ({ balance, fetchData }) => {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const handleErrors = useErrors();

  const methods = useForm({
    defaultValues: {
      amount: "",
    },
    resolver: yupResolver(genSchema(balance)),
  });
  const { watch } = methods;
  const isStaking = watch("wallet_type");
  const onSubmit = async (inputData) => {
    const reqData = new FormData();
    reqData.append("amount", inputData.amount);
    reqData.append("wallet_type", inputData.wallet_type);
    if (isStaking === "staking_wallet") {
      reqData.append("staking_days", inputData.staking_days ?? "");
    }

    try {
      const { message, status } = await (
        await fetchUser.post("ewallet_to_deposit", reqData)
      ).data;

      if (status) {
        enqueueSnackbar(message);
        methods.reset({ amount: "", wallet_type: "", staking_days: "" });
        fetchData();
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.message, { variant: "error" });
      handleErrors(err);
    }
  };

  const currency = useGetCurrencySymbol();

  return (
    <>
      <Card sx={{ p: 3 }}>
        <Box>
          <FormProvider
            methods={methods}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(4, 1fr)",
                },
              }}
            >
              <RHFTextField
                type="number"
                name="amount"
                 label={`${translate("adminFinancial.fundCredit.amount").replace(
                "($)",
                `(${currency})`
              )}`}
                size="small"
              />
              <RHFSelect
                name="wallet_type"
                label={translate("userFinancial.depositWallet.wallet_types")}
                size="small"
              >
                <option />
                <option value="deposit_wallet">
                  {translate("userFinancial.depositWallet.deposit_wallet")}
                </option>
                <option value="staking_wallet">
                  {translate("userFinancial.depositWallet.staking_wallet")}
                </option>
              </RHFSelect>
              {isStaking === "staking_wallet" && (
                <RHFSelect
                  name="staking_days"
                  label={translate(
                    "userFinancial.depositWallet.staking_period"
                  )}
                  size="small"
                >
                  <option />
                  <option value="14">
                    {translate("userFinancial.depositWallet.two_week")}
                  </option>
                  <option value="30">
                    {translate("userFinancial.depositWallet.one_month")}
                  </option>
                  <option value="90">
                    {translate("userFinancial.depositWallet.three_month")}
                  </option>
                  <option value="180">
                    {translate("userFinancial.depositWallet.six_month")}
                  </option>
                  <option value="365">
                    {translate("userFinancial.depositWallet.one_year")}
                  </option>
                </RHFSelect>
              )}

              <Box sx={{ display: "flex" }}>
                <LoadingButton
                  loading={methods.formState.isSubmitting}
                  variant="contained"
                  type="submit"
                  name="add-credit"
                >
                  {translate("userFinancial.depositWallet.add_fund")}
                </LoadingButton>
              </Box>
            </Box>
            {isStaking === "staking_wallet" && (
              <Box mt={2} width="fit-content">
                <Alert severity="success" color="warning">
                  {translate("userFinancial.stakingWallet.staking_info")}
                </Alert>
              </Box>
            )}
          </FormProvider>
        </Box>
      </Card>
    </>
  );
};

export default PayNow;
