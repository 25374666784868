import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PaginationButtons from "src/components/pagination";

import Scrollbar from "src/components/Scrollbar";
import DataFilter from "../../shared/dataFilter";
import useFetchEWallet from "../hooks/useFetchEWallet";
import useLocales from "src/hooks/useLocales";
import EmptyTable from "src/components/emptyTable";
import { useForm } from "react-hook-form";
import Map from "src/components/map";
import DataHandlerTable from "src/components/data-handler/table";
import { capitalCase, snakeCase } from "change-case";
import { filterType } from "src/pages/admin/reports/income/dataList";
import Ternary from "src/components/ternary";
import { formatAmount } from "src/pages/user/incomeReport/components/row";
import { Currency, useGetCurrencySymbol } from "src/components/with-prefix";

export const defaultValues = {
  start_date: "",
  end_date: "",
  user_id: null,
};



export const checkIsWhite = (type) => {
  switch (type) {
    case "fund_transfer":
      return true;
    case "ewallet":
      return true;
    case "deposit_wallet":
      return true;
    case "credited_by_admin":
      return true;
    case "deducted_by_admin":
      return true;
    default:
      return false;
  }
};
const DataTable = () => {
  const methods = useForm({ defaultValues });
  const currency = useGetCurrencySymbol();
  const filter = methods.watch();
  const { translate } = useLocales();
  const { state, fetchData, rowStart, ...rest } = useFetchEWallet(filter);
  const { data, ...dataProps } = state;

  const isEmpty = !Boolean(data?.length);
  const onFilter = methods.handleSubmit((inputData) => {
    fetchData(1, inputData);
  });

  const headers = [
  "adminFinancial.Ewallet.no",
  "adminFinancial.Ewallet.userName",
  "adminFinancial.Ewallet.fromUser",
  "adminFinancial.Ewallet.amountType",
  "Package Name",
  "Daily Profit (%)",
  "adminFinancial.fundCredit.paymentType",
  `${translate("adminFinancial.Ewallet.amount")} (${currency})`,
  "adminFinancial.Ewallet.date",
];

  return (
    <>
      <Card>
        <DataFilter methods={methods} onFilter={onFilter} isWallet="ewallet" />
        <Scrollbar>
          <DataHandlerTable
            name="faq-table"
            headers={headers}
            dataProps={{ ...dataProps }}
          >
            <Map
              list={data}
              render={(item, i) => (
                <>
                  <TableRow key={item.id}>
                    <TableCell>{i + rowStart}</TableCell>
                    <TableCell>{item.user?.username}</TableCell>
                    <TableCell>{item.from_user?.username}</TableCell>
                    <TableCell>{filterType(item.payment_type)}</TableCell>
                    <TableCell>
                      {capitalCase(item.package?.name || "")}
                    </TableCell>
                    <TableCell style={{ color: "#00c59e" }}>
                      {item?.daily_profit_percentage}
                    </TableCell>
                    <TableCell sx={{ textTransform: "capitalize" }}>
                      {item.type}
                    </TableCell>
                    <TableCell>
                      {/* <Ternary
                        when={snakeCase(item.payment_type) === "daily_bonus"}
                        then={
                          <span style={{ color: "green" }}>
                            +$ {item.payable_amount}
                          </span>
                        }
                        otherwise={item.payable_amount}
                      /> */}
                      <Ternary
                        when={snakeCase(item.payment_type) === "fund_transfer"}
                        then={<span><Currency>{(item.payable_amount)}</Currency> </span>}
                        otherwise={
                          <Ternary
                            when={item.type === "debit"}
                            then={
                              <>
                                <Ternary
                                  when={checkIsWhite(
                                    snakeCase(item.payment_type)
                                  )}
                                  then={
                                    <span style={{ color: "white" }}>
                                     <Currency>{(item.payable_amount)}</Currency>
                                    </span>
                                  }
                                  otherwise={
                                    <span style={{ color: "red" }}>
                                      -<Currency>{(item.payable_amount)}</Currency>
                                    </span>
                                  }
                                />
                              </>
                            }
                            otherwise={
                              <>
                                <Ternary
                                  when={checkIsWhite(
                                    snakeCase(item.payment_type)
                                  )}
                                  then={
                                    <span style={{ color: "white" }}>
                                      <Currency>{(item.payable_amount)}</Currency>
                                    </span>
                                  }
                                  otherwise={
                                    <span style={{ color: "#00c59e" }}>
                                      +<Currency>{(item.payable_amount)}</Currency>{" "}
                                    </span>
                                  }
                                />
                              </>
                            }
                          />
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(item.created_at).toLocaleDateString("en-GB")}
                    </TableCell>
                  </TableRow>
                </>
              )}
            />
          </DataHandlerTable>
        </Scrollbar>
        <Divider />
      </Card>
      <PaginationButtons {...rest} />
    </>
  );
};

export default DataTable;
