import { Box, Card, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import { Currency } from "src/components/with-prefix";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Cards = ({ title, color, total, icon }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggleVisibility = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsVisible(!isVisible);
  };
  return (
    <Card sx={{ display: "flex", alignItems: "center", p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h4" style={{ whiteSpace: "pre" }}>
            {isVisible ? <Currency>{total}</Currency> : "****"}
          </Typography>
          <IconButton
            onClick={handleToggleVisibility}
            size="small"
            sx={{ color: "text.secondary" }}
          >
            {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Stack>
      </Box>

      <Box>
        <Iconify color={color} width={60} height={60} icon={icon} />
      </Box>
    </Card>
  );
};

Cards.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

export default Cards;
