// src/layouts/shared/DashboardHeader.jsx

import {
  Badge,
  Box,
  Stack,
  Toolbar,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import Iconify from "src/components/Iconify";
import Logo from "src/components/Logo";
import { IconButtonAnimate } from "src/components/animate";
import Settings from "src/components/settings";
import { HEADER } from "src/config";
import useAuth from "src/hooks/useAuth";
import useOffSetTop from "src/hooks/useOffSetTop";
import useResponsive from "src/hooks/useResponsive";
import { RootStyle } from "src/layouts/shared";
import { PATH_DASHBOARD, PATH_USER } from "src/routes/paths"; // Ensure correct path
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import QuickPopover from "./QuickPopover";
import { useAppContext } from "../../../context";
import useLocales from "src/hooks/useLocales";
import CurrencyPopOver from "./currency-popover";

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 8s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
    tourButton: {
      position: "relative",
      transition: "transform 0.3s, box-shadow 0.3s",
      "&:hover": {
        transform: "scale(1.05)",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      },
    },
    completedBadge: {
      backgroundColor: "#4caf50",
      color: "#fff",
      borderRadius: "50%",
      width: "10px",
      height: "10px",
      position: "absolute",
      top: 0,
      right: 0,
      border: "2px solid white",
    },
  })
);

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const classes = useStyles();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const location = useLocation(); // To check current path
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive("up", "lg");

  const [openSettings, setOpenSettings] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [hasSeenTour, setHasSeenTour] = useState(false);

  const { isAdmin } = useAuth(); // Check if user is admin
  const { state, setState } = useAppContext(); // Joyride context state

  // Define main dashboard routes
  const ADMIN_DASHBOARD_ROUTE = PATH_DASHBOARD.dashboard.business; // e.g., "/admin/dashboard/business"
  const USER_DASHBOARD_ROUTE = PATH_USER.user_dashboard; // e.g., "/user/dashboard"

  // On mount, check if user previously completed the tour
  useEffect(() => {
    const seenTour = localStorage.getItem("hasSeenTour");
    setHasSeenTour(seenTour === "true");
  }, []);

  // Re-check whenever the Joyride `run` state changes (e.g., after finishing the tour)
  useEffect(() => {
    if (localStorage.getItem("hasSeenTour") === "true") {
      setHasSeenTour(true);
    }
  }, [state.run]);

  const handleOpenSettings = () => {
    setOpenSettings((prev) => !prev);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  // Show a confirmation dialog on clicking "Start Tour"
  const handleStartTour = () => {
    setOpenConfirm(true);
  };

  // Called when user confirms they want to start the tour
  const confirmStartTour = () => {
    // 1. Decide the route based on admin or user
    const targetRoute = isAdmin ? ADMIN_DASHBOARD_ROUTE : USER_DASHBOARD_ROUTE;

    // 2. Only navigate if user is NOT already on that route
    if (location.pathname !== targetRoute) {
      navigate(targetRoute);
    }

    // 3. Trigger Joyride state
    setState({
      run: true,
      stepIndex: 0,
      tourActive: true,
      tourType: isAdmin ? "admin" : "user",
    });

    // Close the dialog
    setOpenConfirm(false);
  };

  // If user cancels the confirmation dialog
  const cancelStartTour = () => {
    setOpenConfirm(false);
  };

  return (
    <>
      <RootStyle
        isCollapse={isCollapse}
        isOffset={isOffset}
        verticalLayout={verticalLayout}
      >
        <Toolbar
          sx={{
            backgroundColor: "background.paper",
            minHeight: "100% !important",
            px: { lg: 5 },
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

          {!isDesktop && (
            <IconButtonAnimate
              onClick={onOpenSidebar}
              sx={{ mr: 1, color: "text.primary" }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}

          {/* If admin, show admin-specific icons */}
          {isAdmin && isDesktop && (
            <>
              <Tooltip title="Products">
                <IconButtonAnimate
                  component={Link}
                  to={PATH_DASHBOARD.store.products}
                  sx={{ mr: 1, color: "text.disabled" }}
                >
                  <Iconify icon="material-symbols:shopping-cart-outline" />
                </IconButtonAnimate>
              </Tooltip>

              {/* <Tooltip title="Materials">
                <IconButtonAnimate
                  component={Link}
                  to={PATH_DASHBOARD.store.material}
                  sx={{ mr: 1, color: "text.disabled" }}
                >
                  <Iconify icon="material-symbols:folder-outline" />
                </IconButtonAnimate>
              </Tooltip> */}
              {/* Add more admin quick links as needed */}
            </>
          )}

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            {!isDesktop && <QuickPopover />}

            <CurrencyPopOver/>

            <NotificationsPopover />

            {/* Enhanced "Start Tour" Button with tooltip and styling */}
            <Tooltip
              title={
                hasSeenTour
                  ? "You have completed the tour"
                  : "Start a guided tour of the dashboard"
              }
            >
              <span className={"tour-button-step"}>
                <Button
                  color="inherit"
                  onClick={handleStartTour}
                  startIcon={<Iconify icon="mdi:tour" />}
                  className={classes.tourButton}
                  sx={{
                    position: "relative",
                    transition: "transform 0.3s, box-shadow 0.3s",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    },
                    backgroundColor: hasSeenTour
                      ? "success.main"
                      : "primary.main",
                    color: "common.white",
                    "&:hover": {
                      backgroundColor: hasSeenTour
                        ? "success.dark"
                        : "primary.dark",
                    },
                  }}
                >
                  {translate("start_tour")}
                  {hasSeenTour && <span className={classes.completedBadge} />}
                </Button>
              </span>
            </Tooltip>

            <LanguagePopover />
            <AccountPopover />
          </Stack>
        </Toolbar>
      </RootStyle>

      {/* Settings Sidebar */}
      <Settings handleClose={handleCloseSettings} open={openSettings} />

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirm}
        onClose={cancelStartTour}
        aria-labelledby="confirm-tour-dialog-title"
        aria-describedby="confirm-tour-dialog-description"
      >
        <DialogTitle id="confirm-tour-dialog-title">{"Start Tour"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-tour-dialog-description">
            {`Would you like to start the ${
              isAdmin ? "admin" : "user"
            } tour? This will guide you through the main features of your dashboard.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelStartTour} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmStartTour} color="primary" autoFocus>
            <span>Start</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
