// src/index.js
import "./locales/i18n";
import "./utils/highlight";
import "simplebar/src/simplebar.css";
import "react-image-lightbox/style.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-quill/dist/quill.snow.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";

import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Navigate } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { AuthProvider } from "./contexts/JWTContext";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import CurrencyProvider from "./store/currency";
import AppConfig from "./store/app-config";
import { Suspense } from "react";

ReactDOM.render(
  <Suspense fallback={<></>}>
    <AuthProvider>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <CurrencyProvider>
                  <Suspense fallback={<Navigate to="/" />}>
                    <AppConfig>
                      <App />
                    </AppConfig>
                  </Suspense>
                </CurrencyProvider>
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </AuthProvider>
  </Suspense>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();
reportWebVitals();
